import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "../../../components/notification/NotificationContext";
import { dayOptions } from "./AddWorkoutDay";
import { useCreateWorkoutRoutineContext } from "./CreateWorkoutRoutineContext";
import { useRemoveAllDayExercises } from "./workout-routine-apiClient";

const ExerciseDay = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { routineId } = useParams();
  const { mappedDayWiseData, mutateAddedExercises } =
    useCreateWorkoutRoutineContext();

  const [dayToOperate, setDayToOperate] = useState(undefined);
  const { successNotification, errorNotification } = useNotification();
  const { inProgress, handleExecution, isSuccess, error } =
    useRemoveAllDayExercises();

  useEffect(() => {
    if (isSuccess) {
      mutateAddedExercises();
      successNotification("Deleted Successfully");
      setIsModalOpen(false);
    }
    if (error) {
      errorNotification();
    }
  }, [
    error,
    errorNotification,
    isSuccess,
    mutateAddedExercises,
    successNotification,
  ]);

  return (
    <>
      {/* <DeleteModal
        isOpen={isModalOpen}
        isLoading={inProgress}
        onSubmit={handleExecution({
          workoutRoutineId: Number(routineId),
          dayId: dayToOperate,
        })}
        onCancel={() => setIsModalOpen(false)}
      /> */}
      <div className="flex flex-col w-full sm:max-w-[400px] p-2 gap-2">
        {Object.keys(mappedDayWiseData).map((item, index) => {
          const day = dayOptions.find((day) => {
            return Number(day.value) === Number(item);
          });
          if (!day) return null;
          return (
            <div
              className="flex justify-between p-3 bg-slate-50 rounded-xl gap-2"
              key={index}
            >
              <div className="flex flex-col gap-2 truncate">
                <div className="text-base font-medium truncate">
                  {day.label}
                </div>
                <div className="text-sm ">
                  Exercises :{" "}
                  <span className="font-bold">
                    {mappedDayWiseData[day.value].length ?? 0}
                  </span>
                </div>
              </div>
              <div className="flex gap-3 items-center">
                <EditButton
                  onClick={() =>
                    navigate(`/add-exercise/${routineId}/${day.value}`)
                  }
                />
                <CloseButton
                  onClick={() => [
                    setDayToOperate(day.value),
                    setIsModalOpen(true),
                  ]}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ExerciseDay;

export const CloseButton = ({ onClick }) => {
  return <Button icon={<DeleteOutlined />} danger onClick={onClick} />;
};

export const EditButton = ({ onClick }) => {
  return <Button icon={<EditOutlined />} onClick={onClick} />;
};
