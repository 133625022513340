import dayjs from "dayjs";
import { groupBy, mapValues } from "lodash";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { BarChart } from "../../../components/charts/BarChart";
import { useGetRoutineSet } from "./api-client";

const Performance = () => {
  const { dayId, routineId, exerciseId, memberId } = useParams();
  const { data } = useGetRoutineSet({
    dayId: Number(dayId),
    routineId: Number(routineId),
    exerciseId: Number(exerciseId),
    userId: Number(memberId),
  });

  const logsData = useMemo(
    () =>
      data?.data?.sort(
        (item1, item2) =>
          dayjs(item1.logDate).unix() - dayjs(item2.logDate).unix()
      ) ?? [],
    [data]
  );

  const mappedData = useMemo(() => {
    const removeTime = logsData.map((item) => {
      return {
        ...item,
        logDate: dayjs(item.logDate ?? item.createdAt).startOf("day"),
      };
    });

    return mapValues(groupBy(removeTime ?? [], "logDate"), (clist) => {
      return clist.reduce((acc, item) => acc + Number(item.weight), 0);
    });
  }, [logsData]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: true,
        text: "Exercise Performance",
      },
    },
  };

  const chartLabels = Object.keys(mappedData).map((item) =>
    dayjs(item).format("DD/MM/YYYY")
  );

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: "Toal Weight Lifted (In KG)",
        data: Object.values(mappedData),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div className="flex flex-col items-center justify-center bg-slate-50 w-full rounded-[16px] mt-6 p-4 h-[450px] max-h-[450px]">
      <BarChart options={options} data={chartData} />
    </div>
  );
};

export default Performance;
