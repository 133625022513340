import { MoreOutlined } from "@ant-design/icons";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { hideAll } from "tippy.js";

const MoreOptionsBtn = ({ options, onClick }) => {
  return (
    <Tippy
      content={
        <div className="flex flex-col">
          {options.map((option, index) => {
            const { label, onClick, className } = option;
            return (
              <Options
                key={index}
                onClick={() => [onClick?.(), hideAll()]}
                label={label}
                className={className}
              />
            );
          })}
        </div>
      }
      trigger="click"
      interactive
      arrow={false}
      className="shadow-md"
      placement="bottom"
      hideOnClick
    >
      <div
        className="flex justify-center items-center h-[36px] w-[36px] rounded-full bg-white cursor-pointer"
        onClick={onClick}
      >
        <MoreOutlined />
      </div>
    </Tippy>
  );
};

export default MoreOptionsBtn;

const Options = ({ onClick, label, className }) => {
  return (
    <div className="w-[200px] p-2 ">
      <div
        className={classNames(" cursor-pointer", className)}
        onClick={onClick}
      >
        {label}
      </div>
    </div>
  );
};
