import { EditOutlined } from "@ant-design/icons";
import {
  BuildingOfficeIcon,
  Cog8ToothIcon,
  HomeIcon,
  UserGroupIcon,
  UsersIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";

export const TAB_NAMES = {
  DASHBOARD: "DASHBOARD",
  MEMBERS: "MEMBERS",
  PAYMENTS: "PAYMENTS",
  PLANS: "PLANS",
  EXPENSES: "EXPENSES",
  STAFF: "STAFF",
  GYM: "GYM",
  SETTINGS: "SETTINGS",
  WORKOUTS: "WORKOUTS",
};

export const staffOptions = (gymId) => [
  {
    label: "Members",
    name: TAB_NAMES.MEMBERS,
    href: `/`,
    icon: UsersIcon,
  },
  {
    label: "Expenses",
    name: TAB_NAMES.EXPENSES,
    href: `/expenses`,
    icon: WalletIcon,
  },
  {
    label: "Workouts",
    name: TAB_NAMES.WORKOUTS,
    href: `/workouts`,
    icon: EditOutlined,
  },
  {
    label: "Gym",
    name: TAB_NAMES.GYM,
    href: `/gym`,
    icon: BuildingOfficeIcon,
  },
  {
    label: "Account Settings",
    name: TAB_NAMES.SETTINGS,
    href: `/settings`,
    icon: Cog8ToothIcon,
  },
];

export const adminNavOptions = (gymId) => [
  {
    label: "Dashboard",
    name: TAB_NAMES.DASHBOARD,
    href: `/`,
    icon: HomeIcon,
  },

  // {
  //   label: "Payments",
  //   name: TAB_NAMES.PAYMENTS,
  //   href: `/payments`,
  //   icon: CreditCardIcon,
  // },

  {
    label: "Members",
    name: TAB_NAMES.MEMBERS,
    href: `/members`,
    icon: UsersIcon,
  },
  {
    label: "Staff",
    name: TAB_NAMES.STAFF,
    href: `/staff`,
    icon: UserGroupIcon,
  },
  {
    label: "Expenses",
    name: TAB_NAMES.EXPENSES,
    href: `/expenses`,
    icon: WalletIcon,
  },
  {
    label: "Workouts",
    name: TAB_NAMES.WORKOUTS,
    href: `/workouts`,
    icon: EditOutlined,
  },
  {
    label: "Gym",
    name: TAB_NAMES.GYM,
    href: `/gym`,
    icon: BuildingOfficeIcon,
  },
  {
    label: "Account Settings",
    name: TAB_NAMES.SETTINGS,
    href: `/settings`,
    icon: Cog8ToothIcon,
  },
];
