import { Button, Select } from "antd";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import TabHeader from "../../../components/layouts/TabHeader";
import CreateWorkoutRoutineContextProvider, {
  useCreateWorkoutRoutineContext,
} from "./CreateWorkoutRoutineContext";
import ExerciseDay from "./ExerciseDays";

const FieldNames = {
  DAY: "day",
};

const AddWorkoutDay = () => {
  return (
    <CreateWorkoutRoutineContextProvider>
      <WorkoutDayContent />
    </CreateWorkoutRoutineContextProvider>
  );
};

export default AddWorkoutDay;

const WorkoutDayContent = () => {
  const { routineId } = useParams();
  const navigate = useNavigate();
  const [workoutDay, setWorkoutDay] = useState(null);

  const { daysWhichHasExercisesAdded, mappedDayWiseData } =
    useCreateWorkoutRoutineContext();

  const { values, touched, errors, handleSubmit, setFieldValue } = useFormik({
    // enableReinitialize: true,
    initialValues: {
      day: workoutDay,
    },
    validationSchema: Yup.object({
      [FieldNames.DAY]: Yup.string().required("This is a required field."),
    }),
    onSubmit: (values) => {
      setWorkoutDay(values[FieldNames.DAY]);
      navigate(`/add-exercise/${routineId}/${values[FieldNames.DAY]}`);
    },
  });

  const selectedDayValue = useMemo(() => values[FieldNames.DAY], [values]);

  const selectedDay = useMemo(
    () => dayOptions.find((day) => day.value === selectedDayValue),
    [selectedDayValue]
  );

  const availableDayOptions = useMemo(() => {
    return dayOptions.filter((day) => {
      return !Object.keys(mappedDayWiseData).includes(String(day.value));
    });
  }, [mappedDayWiseData]);

  return (
    <div className="flex flex-col gap-5 h-full w-full -mt-4 ">
      <TabHeader
        label={"Select Day"}
        // onBackClick={() => navigate("/workouts")}
        showBackBtn
      />

      <div className="flex flex-col items-center justify-center bg-white h-fit w-full rounded-[16px] pb-[70px]">
        <div className="flex justify-center flex-col gap-3 mt-6 w-full sm:max-w-[400px] p-2">
          <form className="flex flex-col w-full gap-5" onSubmit={handleSubmit}>
            <p>
              <span className="font-bold">Instruction:</span> Select a Day for
              which you want to add exercises.
            </p>
            <Select
              placeholder="Select Workout Day"
              options={availableDayOptions}
              value={selectedDayValue}
              onChange={(value) => setFieldValue(FieldNames.DAY, Number(value))}
              errorMessage={
                touched[FieldNames.DAY] ? errors[FieldNames.DAY] : undefined
              }
            />
            <Button htmlType="submit" type="primary">
              {selectedDayValue
                ? `Add Exercise for ${selectedDay.label}`
                : "Add Exercise"}
            </Button>
            {/* {selectedDayValue && (
              <Button
                text={`Mark ${selectedDay.label} as Rest Day`}
                version={ButtonVersion.TWO}
              />
            )} */}
          </form>
        </div>
        <ExerciseDay />
      </div>
    </div>
  );
};

export const DAYS = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0,
};

export const dayOptions = [
  {
    label: "Monday",
    value: DAYS.MONDAY,
  },
  {
    label: "Tuesday",
    value: DAYS.TUESDAY,
  },
  {
    label: "Wednesday",
    value: DAYS.WEDNESDAY,
  },
  {
    label: "Thursday",
    value: DAYS.THURSDAY,
  },
  {
    label: "Friday",
    value: DAYS.FRIDAY,
  },
  {
    label: "Saturday",
    value: DAYS.SATURDAY,
  },
  {
    label: "Sunday",
    value: DAYS.SUNDAY,
  },
];
