export const getActiveTab = (state) => state.app.activeTab;

export const isGymInfoInProgress = (state) =>
  state.userInfo.inProgress ?? false;
export const getGymAdminInfo = (state) => state.userInfo?.data ?? {};

export const isGymListApiInProgress = (state) =>
  state.gyms?.inProgress ?? false;
export const getGymsList = (state) => state.gyms?.data ?? [];

export const hasGymsAdded = (state) => {
  return getGymsList(state).length > 0;
};

export const getActiveGymInfo = (state, gymId) => {
  return getGymsList(state).find((item) => item.gymId === gymId);
};

export const getActiveGymId = (state) =>
  state.app?.activeGymId ?? "0000-0000-0000-0000";

export const getUserRoleForActiveGym = (state) => {
  return getGymsList(state).find((gym) => gym.gymId === getActiveGymId(state))
    ?.role;
};

export const isOnboardingModalOpen = (state) =>
  state.app?.onBoardingModalStatus ?? false;

export const getGlobalFilters = (state) => state.app.dateFilter;
