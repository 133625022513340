import { useNavigate } from "react-router-dom";

import TabHeader from "../../../components/layouts/TabHeader";
import RoutineDays from "../routine-days/RoutineDays";

const ChooseWorkoutDay = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-5">
      <TabHeader
        label={"Select Workout Day"}
        showBackBtn
        // onBackClick={() => navigate('/workouts')}
      />

      <RoutineDays />
    </div>
  );
};

export default ChooseWorkoutDay;
