import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Button } from "antd";
import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../components/modals/DeleteModal";
import { useNotification } from "../../../components/notification/NotificationContext";
import { mapperDayWise } from "../../../utils/helpers";
import { dayOptions } from "../create/AddWorkoutDay";
import {
  useGetAddedExercises,
  useRemoveAllDayExercises,
} from "../create/workout-routine-apiClient";

const RoutineDays = () => {
  const { routineId, memberId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const {
    inProgress: getExerciseInProgress,
    data: addedExercises,
    mutate: mutateAddedExercises,
  } = useGetAddedExercises(Number(routineId));

  const [dayToOperate, setDayToOperate] = useState(undefined);
  const { successNotification, errorNotification } = useNotification();
  const { inProgress, handleExecution, isSuccess, error } =
    useRemoveAllDayExercises();

  useEffect(() => {
    if (isSuccess) {
      mutateAddedExercises();
      successNotification("Deleted Successfully");
      setIsModalOpen(false);
    }
    if (error) {
      errorNotification();
    }
  }, [
    error,
    errorNotification,
    isSuccess,
    mutateAddedExercises,
    successNotification,
  ]);

  const addedExercisesData = useMemo(
    () => addedExercises?.data ?? [],
    [addedExercises?.data]
  );

  const mappedDayWiseData = useMemo(() => {
    return mapperDayWise(addedExercisesData);
  }, [addedExercisesData]);

  if (getExerciseInProgress) {
    return (
      <div className="flex flex-col gap-3 justify-center">
        {Array.from(Array(5).keys()).map((row, index) => {
          return (
            <Skeleton
              baseColor="#FFFFFF"
              highlightColor="#f3f4f6"
              key={index}
              width={"100%"}
              height={76}
              className="roundex-xl"
            />
          );
        })}
      </div>
    );
  }

  return (
    <>
      <DeleteModal
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        isLoading={inProgress}
        onSubmit={() =>
          handleExecution({
            workoutRoutineId: Number(routineId),
            dayId: dayToOperate,
          })
        }
      />
      <div className="flex flex-col w-full gap-3 pb-[64px]">
        {Object.keys(mappedDayWiseData).map((item, index) => {
          const day = dayOptions.find((day) => {
            return Number(day.value) === Number(item);
          });
          if (!day) return null;

          return (
            <div
              className="flex justify-between p-3 bg-slate-50 rounded-xl gap-2"
              key={index}
            >
              <div className="flex flex-col gap-2 truncate">
                <div className="text-base font-medium truncate">
                  {day.label}
                </div>
                <div className="text-sm text-[#F6A800]">
                  Exercises :{" "}
                  <span className="font-bold">
                    {mappedDayWiseData[day.value].length ?? 0}
                  </span>
                </div>
              </div>
              <div className="flex gap-3 items-center">
                <Button
                  onClick={() =>
                    navigate(
                      memberId
                        ? `/workout-exercise/${routineId}/${item}/${memberId}`
                        : `/workout-exercise/${routineId}/${item}`
                    )
                  }
                  icon={<ArrowRightIcon className="h-4 w-4" />}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RoutineDays;
