import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import DeleteRoutineModal from "./added-workout-routines/DeleteRoutineModal";
import { useDeleteWorkoutRoutine, useGetAllWorkoutRoutines } from "./apiClient";

import { useNotification } from "../../components/notification/NotificationContext";
import ChangeRoutineName from "./added-workout-routines/ChangeRoutineName";

const WorkoutRoutineContext = React.createContext({});

const WorkoutRoutineContextProvider = ({ children, memberId }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);
  const [idToOperator, setIdToOperate] = useState(undefined);
  const { inProgress, data, mutate } = useGetAllWorkoutRoutines({
    userId: memberId,
  });
  const {
    inProgress: isDeleteInProgress,
    execute: deleteRoutine,
    error: deleteEror,
    isSuccess: isDeletedSuccessfully,
  } = useDeleteWorkoutRoutine();

  const useAssignmentFlow = useMemo(() => !!memberId, [memberId]);

  const { successNotification, errorNotification } = useNotification();

  const workoutRoutinesData = useMemo(() => {
    return data?.data ?? [];
  }, [data?.data]);

  const hasWorkoutRoutines = useMemo(
    () => workoutRoutinesData.length > 0,
    [workoutRoutinesData.length]
  );

  const handleDeleteRoutineModal = useCallback(() => {
    setIsDeleteModalOpen(true);
  }, []);

  useEffect(() => {
    if (isDeletedSuccessfully) {
      successNotification("Routine Deleted Succesfully");
      setIsDeleteModalOpen(false);
      mutate();
    }
    if (deleteEror) {
      errorNotification();
    }
  }, [
    deleteEror,
    errorNotification,
    isDeletedSuccessfully,
    mutate,
    successNotification,
  ]);

  const routineDataToOperate = useMemo(() => {
    return workoutRoutinesData.find((routine) => routine.id === idToOperator);
  }, [idToOperator, workoutRoutinesData]);

  const routineNameToOperate = useMemo(() => {
    return routineDataToOperate?.name;
  }, [routineDataToOperate?.name]);

  return (
    <>
      <DeleteRoutineModal
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        onDelete={() => deleteRoutine(idToOperator)}
        isDeleteInProgress={isDeleteInProgress}
      />
      {idToOperator && (
        <ChangeRoutineName
          isModalOpen={isEditNameModalOpen}
          setIsModalOpen={setIsEditNameModalOpen}
          value={routineNameToOperate}
          routineId={idToOperator}
        />
      )}
      <WorkoutRoutineContext.Provider
        value={{
          inProgress,
          hasWorkoutRoutines,
          workoutRoutinesData,
          handleDeleteRoutineModal,
          setIdToOperate,
          setIsEditNameModalOpen,
          useAssignmentFlow,
          refreshRoutineList: mutate,
        }}
      >
        {children}
      </WorkoutRoutineContext.Provider>
    </>
  );
};

export default WorkoutRoutineContextProvider;

export const useWorkoutRoutineContext = () => {
  return useContext(WorkoutRoutineContext);
};
