import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOnBoardingModalStatus } from "../../../redux/actions/app";
import {
  hasGymsAdded,
  isOnboardingModalOpen,
} from "../../../redux/selectors/app";
import GymInfo from "../info/GymInfo";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

function OnboardingModal() {
  const dispatch = useDispatch();
  const isModalOpen = useSelector(isOnboardingModalOpen);
  const hasGyms = useSelector(hasGymsAdded);

  const closeModal = () => {
    dispatch(setOnBoardingModalStatus(false));
  };

  return (
    <Modal
      open={isModalOpen}
      closable={hasGyms}
      maskClosable={hasGyms}
      onCancel={closeModal}
      footer={null}
    >
      <GymInfo shouldUseInitialValues={false} />
    </Modal>
  );
}

export default OnboardingModal;
