import { Button, Modal } from "antd";

const DeleteModal = ({ isOpen, isLoading, onSubmit, onCancel }) => {
  return (
    <Modal
      open={isOpen}
      title="Are you sure, you want to delete this ?"
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={onSubmit}
          danger
        >
          Delete
        </Button>,
      ]}
    ></Modal>
  );
};

export default DeleteModal;
