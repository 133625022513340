import { useCallback } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import useFetchAsync from "../../hooks/useFetchAsync";
import { deleteReq, get, patch, post } from "../../http-clients/clients";
import { getActiveGymId } from "../../redux/selectors/app";

export const useCreateGym = () => {
  const createGym = useCallback((data) => {
    return post("/gym/create", { ...data });
  }, []);

  return useFetchAsync(createGym);
};

export const useUpdateGym = () => {
  const gymId = useSelector(getActiveGymId);
  const createGym = useCallback(
    (data) => {
      return patch(`/gym/update/${gymId}`, { ...data });
    },
    [gymId]
  );

  return useFetchAsync(createGym);
};

export const useAddGalleryImage = () => {
  const gymId = useSelector(getActiveGymId);
  const createGym = useCallback(
    (data) => {
      return post(`/gym/gallery/${gymId}`, { image: data });
    },
    [gymId]
  );

  return useFetchAsync(createGym);
};

export const useGetGalleryImages = () => {
  const gymId = useSelector(getActiveGymId);
  return useSWR(`/gym/gallery/${gymId}`, get);
};

export const useRemoveGalleryImage = () => {
  const gymId = useSelector(getActiveGymId);
  const createGym = useCallback(
    (imageId) => {
      return deleteReq(`/gym/gallery/${gymId}`, { imageId });
    },
    [gymId]
  );

  return useFetchAsync(createGym);
};

export const useGetAmenities = () => {
  const gymId = useSelector(getActiveGymId);
  return useSWR(`/gym/amenity/${gymId}`, get);
};

export const useAddAmenities = () => {
  const gymId = useSelector(getActiveGymId);
  const createGym = useCallback(
    (data) => {
      return post(`/gym/amenity/${gymId}`, { data });
    },
    [gymId]
  );

  return useFetchAsync(createGym);
};
