import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Button } from "antd";
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import TabHeader from "../../../components/layouts/TabHeader";
import { mapperDayWise, wellFormedImageUrl } from "../../../utils/helpers";
import { useGetAddedExercises } from "../create/workout-routine-apiClient";

const DayExercises = () => {
  const navigate = useNavigate();
  const { routineId, dayId, memberId } = useParams();
  const {
    inProgress,
    data: addedExercises,
    mutate: mutateAddedExercises,
  } = useGetAddedExercises(Number(routineId));

  const addedExercisesData = useMemo(
    () => addedExercises?.data ?? [],
    [addedExercises?.data]
  );

  const mappedDayWiseData = useMemo(() => {
    return mapperDayWise(addedExercisesData);
  }, [addedExercisesData]);

  const exercisesForTheDay = useMemo(() => {
    return mappedDayWiseData[dayId];
  }, [dayId, mappedDayWiseData]);

  const loader = useMemo(() => {
    return (
      <div className="flex flex-col gap-3 justify-center">
        {Array.from(Array(5).keys()).map((row, index) => {
          return (
            <Skeleton
              baseColor="#FFFFFF"
              highlightColor="#f3f4f6"
              key={index}
              width={"100%"}
              height={94}
              className="roundex-xl"
            />
          );
        })}
      </div>
    );
  }, []);

  return (
    <div className="flex flex-col gap-5 ">
      <TabHeader
        label={"Select Exercise"}
        showBackBtn
        //onBackClick={() => navigate(`/start-workout/${routineId}`)}
      />
      <div className="flex flex-col w-full gap-3 pb-[64px]">
        {inProgress
          ? loader
          : exercisesForTheDay?.map((item, index) => {
              const { Exercise } = item;
              const { title, image, id } = Exercise;
              return (
                <div
                  className="flex justify-between pr-3 bg-slate-50 rounded-xl gap-2"
                  key={index}
                >
                  <div className="flex gap-3 items-center">
                    <img
                      src={image ? `${wellFormedImageUrl(image)}` : null}
                      alt="exercise"
                      className="roundex-xl object-cover h-[94px] w-[94px] rounded-l-xl"
                    />
                    <div className="flex flex-col gap-2 ">
                      <div className="text-base font-medium flex flex-wrap">
                        {title}
                      </div>
                    </div>
                  </div>
                  {memberId && (
                    <div className="flex gap-3 items-center">
                      <Button
                        onClick={() =>
                          navigate(
                            `/log-exercise/${routineId}/${dayId}/${id}/${memberId}`
                          )
                        }
                        icon={<ArrowRightIcon className="h-4 w-4" />}
                      />
                    </div>
                  )}
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default DayExercises;
