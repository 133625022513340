import { Button } from "antd";
import Search from "antd/es/input/Search";
import { memo, useCallback, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import TabHeader from "../../../components/layouts/TabHeader";
import { EXERCISE_FILTERS } from "../exercise-filters/constants";
import {
  ExercsiseFilterContextProvider,
  useFilterContext,
} from "../exercise-filters/ExerciseFilterContext";
import { FilterButton } from "../exercise-filters/FilterButton";
import { AddedExerciseModal } from "./AddedExerciseModal";
import CreateWorkoutRoutineContextProvider, {
  useCreateWorkoutRoutineContext,
} from "./CreateWorkoutRoutineContext";
import ExerciseCard from "./ExerciseCard";

const AddExercises = () => {
  const navigate = useNavigate();
  const { routineId } = useParams();

  return (
    <div className="flex flex-col gap-5 h-full w-full -mt-4 ">
      <TabHeader
        label={"Select Exercise"}
        // onBackClick={() => navigate(`/workout-day/${routineId}`)}
        showBackBtn
      />
      <CreateWorkoutRoutineContextProvider>
        <ExercsiseFilterContextProvider filterOptions={EXERCISE_FILTERS}>
          <AddExerciseContent />
        </ExercsiseFilterContextProvider>
      </CreateWorkoutRoutineContextProvider>
    </div>
  );
};

export default memo(AddExercises);

const AddExerciseContent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { routineId, dayId } = useParams();
  const navigate = useNavigate();

  const [addExerciseId, setAddExerciseId] = useState(undefined);

  const {
    inProgress,
    exerciseList: exercisesData,
    searchText,
    setSearchText,
    setStart,
    handleExecution,
    setExerciseData,
    totalCount,
    handleSubmit,
    handleSearch,
  } = useFilterContext();
  const hasData = exercisesData.length > 0;

  const {
    addedExercisesData,
    deleteExerciseFromRoutine,
    addExercise,
    isAddExerciseInProgress,
    isRemoveInProgress,
  } = useCreateWorkoutRoutineContext();

  const addedExercisesForSelectedDay = useMemo(() => {
    return addedExercisesData.filter(
      (exercise) => exercise.dayId === Number(dayId)
    );
  }, [addedExercisesData, dayId]);

  const addedExercisesForSelectedDayLength = useMemo(
    () => addedExercisesForSelectedDay.length,
    [addedExercisesForSelectedDay.length]
  );

  const handleFinishClick = useCallback(() => {
    navigate(`/workout-day/${routineId}`);
  }, [navigate, routineId]);

  const progressContent = (
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 pb-20">
      {Array.from(Array(8).keys()).map((row, index) => {
        return (
          <Skeleton
            baseColor="#FFFFFF"
            highlightColor="#f3f4f6"
            key={index}
            width={"100%"}
            height={139}
          />
        );
      })}
    </div>
  );

  const isAddOrRemoveInProgress = useCallback(
    (exerciseId) =>
      exerciseId === addExerciseId &&
      (isAddExerciseInProgress || isRemoveInProgress),
    [addExerciseId, isAddExerciseInProgress, isRemoveInProgress]
  );

  const onExerciseRemove = useCallback(
    (exerciseId) => {
      setAddExerciseId(exerciseId);
      deleteExerciseFromRoutine({
        routineId: Number(routineId),
        exerciseId: exerciseId,
        dayId: Number(dayId),
      });
    },
    [dayId, deleteExerciseFromRoutine, routineId]
  );

  const onExerciseAdd = useCallback(
    (exerciseId) => {
      setAddExerciseId(exerciseId);
      addExercise({
        dayId: Number(dayId),
        workoutRoutineId: Number(routineId),
        exerciseId: exerciseId,
      });
    },
    [addExercise, dayId, routineId]
  );

  return (
    <>
      {isModalOpen && (
        <AddedExerciseModal
          isModalOpen={isModalOpen}
          setIsModalOpen={() => setIsModalOpen(false)}
        />
      )}
      <div className="flex flex-col gap-5 relative h-full">
        <div className="flex gap-3 justify-end">
          {/* <Input
            label="Search Exercise"
            name={"search"}
            value={searchText}
            onChange={(value) => {
              // setStart(0);

              setSearchText(value);
              setTimeout(() => {
                handleSubmit();
              }, 2000);
            }}
          /> */}
          <Search
            placeholder="Search Email"
            onSearch={(value) => handleSearch(value)}
            style={{
              maxWidth: 300,
            }}
          />
          <FilterButton />
        </div>
        {addedExercisesForSelectedDayLength > 0 && (
          <div className="flex items-center justify-between bg-slate-50 rounded-t-xl p-3 fixed sm:static bottom-[58px] left-0 right-0 sm:bottom-0 z-10 sm:z-0">
            <div className="flex flex-col">
              <div>{addedExercisesForSelectedDayLength} Exercises Added</div>
              <div
                className="text-[#F6A800] text-sm cursor-pointer"
                onClick={() => setIsModalOpen(true)}
              >
                View Added Exercises
              </div>
            </div>
            <Button onClick={handleFinishClick} type="primary">
              Finish
            </Button>
          </div>
        )}

        {!hasData && !inProgress && totalCount === 0 && (
          <div className="flex flex-col items-center justify-center bg-slate-50 h-full w-full rounded-[16px]">
            No Data Found
          </div>
        )}
        {
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 ">
            {exercisesData?.map((exercise, index) => {
              const isExerciseAdded =
                addedExercisesData.filter(
                  (addedExercise) =>
                    addedExercise.exerciseId === exercise.id &&
                    addedExercise.dayId === Number(dayId)
                ).length > 0;
              return (
                <ExerciseCard
                  key={exercise.id}
                  image={exercise.image}
                  title={exercise.title}
                  bodyParts={exercise.bodyParts}
                  onExerciseAdd={() => onExerciseAdd(exercise.id)}
                  isExerciseAdded={isExerciseAdded}
                  onExerciseRemove={() => onExerciseRemove(exercise.id)}
                  isAddInProgress={isAddOrRemoveInProgress(exercise.id)}
                />
              );
            })}
          </div>
        }
        {inProgress && progressContent}
        {totalCount > 10 && !inProgress && (
          <Button
            loading={inProgress}
            className="shrink-0"
            onClick={() => [setStart((prev) => prev + 10)]}
          >
            Load More
          </Button>
        )}
        <div className="pb-[120px]"></div>
      </div>
    </>
  );
};
