import { createContext, useContext, useState } from "react";

const FilterContext = createContext({});
const FilterContextProvider = ({ children, filterOptions }) => {
  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const [appliedFilters, setAppliedFilters] = useState({});

  return (
    <FilterContext.Provider
      value={{
        filterOptions,
        selectedFilter,
        setSelectedFilter,
        appliedFilters,
        setAppliedFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterContextProvider;

export const useFilterContext = () => {
  return useContext(FilterContext);
};
