import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { useWorkoutRoutineContext } from "../WorkoutRoutineContext";
import RoutineCard from "./RoutineCard";

const RoutinesList = () => {
  const navigate = useNavigate();

  const { workoutRoutinesData, inProgress } = useWorkoutRoutineContext();

  if (inProgress) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        {Array.from(Array(3).keys()).map((row, index) => {
          return (
            <Skeleton
              baseColor="#FFFFFF"
              highlightColor="#f3f4f6"
              key={index}
              width={"100%"}
              height={198}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
      {workoutRoutinesData.map((routine) => {
        const { id, name, createdAt, RoutineExercises = [] } = routine;
        const unique = [...new Set(RoutineExercises.map((item) => item.dayId))];

        return (
          <RoutineCard
            id={id}
            title={name}
            createdAt={dayjs(createdAt).format("MMMM D YYYY")}
            workingDays={unique.length ?? 0}
            key={id}
          />
        );
      })}
    </div>
  );
};

export default RoutinesList;
