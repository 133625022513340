import { Modal, Select } from "antd";
import { useFormik } from "formik";
import { memo, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { number, object } from "yup";
import { ElementWithLabel } from "../../../components/inputs/Input";
import { useNotification } from "../../../components/notification/NotificationContext";
import { useGetAllWorkoutRoutines } from "../../workouts/apiClient";
import { useAssignWorkoutTemplate } from "../apis";

const FORM_FIELDS = {
  ROUTINE: "routine",
};

const AssignRoutineModal = ({ isOpen, onCancel }) => {
  const { errorNotification, successNotification } = useNotification();
  const { memberId } = useParams();
  const { data, mutate } = useGetAllWorkoutRoutines();

  const { inProgress, isSuccess, error, execute } = useAssignWorkoutTemplate();

  const workoutRoutinesData = useMemo(
    () =>
      (data?.data ?? []).map((item) => ({ label: item.name, value: item.id })),
    [data?.data]
  );

  const { values, errors, handleSubmit, handleChange, setFieldValue, touched } =
    useFormik({
      enableReinitialize: true,
      validateOnMount: false,
      initialValues: {
        [FORM_FIELDS.ROUTINE]: null,
      },
      validationSchema: object({
        [FORM_FIELDS.ROUTINE]: number().required("This is a Required field."),
      }),
      onSubmit: (data) => {
        // if (memberId) {
        //   updateMember(data);
        //   return;
        // }
        execute({ userId: memberId, routineId: data[FORM_FIELDS.ROUTINE] });
      },
    });

  useEffect(() => {
    if (isSuccess) {
      onCancel();
      successNotification();
    }
  }, [isSuccess, onCancel, successNotification]);

  useEffect(() => {
    if (error) {
      errorNotification();
    }
  }, [error, errorNotification]);

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      title="Assign Workout Routine"
      okText="Assign"
      onOk={handleSubmit}
      confirmLoading={inProgress}
    >
      <div className="flex flex-col">
        <ElementWithLabel
          label="Routines"
          error={
            errors[FORM_FIELDS.ROUTINE] &&
            touched[FORM_FIELDS.ROUTINE] &&
            errors[FORM_FIELDS.ROUTINE]
          }
        >
          <Select
            showSearch
            placeholder="Select Routine"
            optionFilterProp="children"
            value={values[FORM_FIELDS.ROUTINE]}
            onChange={(value) => setFieldValue(FORM_FIELDS.ROUTINE, value)}
            onSearch={() => {}}
            options={workoutRoutinesData}
          />
        </ElementWithLabel>
      </div>
    </Modal>
  );
};

export default memo(AssignRoutineModal);
