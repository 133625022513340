import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import Input from "../../../components/inputs/Input";
import { useNotification } from "../../../components/notification/NotificationContext";
import { HorizontalSeparator } from "../../members/create/AddMember";
import {
  useEditWorkoutRoutineName,
  useGetAllWorkoutRoutines,
} from "../apiClient";

const ChangeRoutineName = ({
  isModalOpen,
  setIsModalOpen,
  value,
  routineId,
}) => {
  const { mutate } = useGetAllWorkoutRoutines();
  const { successNotification, errorNotification } = useNotification();
  const [title, setTitle] = useState(undefined);

  const {
    inProgress,
    isSuccess,
    error,
    execute: handleExecution,
  } = useEditWorkoutRoutineName();

  useEffect(() => {
    if (value) {
      setTitle(value);
    }
  }, [value]);

  useEffect(() => {
    if (isSuccess) {
      successNotification("Changed routine name.");

      mutate();
      setIsModalOpen(false);
    }
    if (error) {
      errorNotification();
    }
  }, [
    error,
    errorNotification,
    isSuccess,
    mutate,
    setIsModalOpen,
    successNotification,
  ]);

  return (
    <Modal
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <div className="flex flex-col">
        <div className="flex gap-3 items-center p-3">
          <div className="text-xl font-semibold">{"Change Routine Name"}</div>
        </div>
        <HorizontalSeparator className="-mx-3" />

        <div className="py-3">
          <Input value={title} onChange={(e) => [setTitle(e.target.value)]} />
        </div>

        <div className="flex p-3 gap-3 justify-end">
          <Button
            disabled={inProgress}
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            loading={inProgress}
            onClick={() =>
              handleExecution({ workoutRoutineId: routineId, name: title })
            }
            type="primary"
          >
            Update
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeRoutineName;
