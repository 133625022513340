import dayjs from "dayjs";
import {
  SET_ACTIVE_GYM_ID,
  SET_ACTIVE_TAB,
  SET_GLOBAL_FILTERS,
  SET_ONBOARDING_MODAL_STATUS,
} from "../actions/app";

const initialState = {
  message: "hello",
  activeTab: undefined,
  activeGymId: undefined,
  onBoardingModalStatus: false,
  dateFilter: {
    startDate: dayjs().year(),
    endDate: dayjs().year(),
  },
};

const appReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case "HELLO_WORLD":
      return {
        ...state,
        message: "hello world",
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload.payload,
      };
    case SET_ACTIVE_GYM_ID:
      return {
        ...state,
        activeGymId: payload.payload,
      };
    case SET_ONBOARDING_MODAL_STATUS:
      return {
        ...state,
        onBoardingModalStatus: payload.payload,
      };
    case SET_GLOBAL_FILTERS:
      return {
        ...state,
        dateFilter: payload.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
