import useSWR from "swr";

import { useMemo } from "react";
import { get } from "../http-clients/clients";

const useSwrFetch = (url, mapper) => {
  const { isLoading, data, error, mutate, ...rest } = useSWR(url, get);

  const mappedData = useMemo(() => {
    if (isLoading) return;
    if (mapper) {
      return mapper(data);
    }
    return data;
  }, [data, isLoading, mapper]);

  return { inProgress: isLoading, data: mappedData, error, mutate, ...rest };
};

export default useSwrFetch;
