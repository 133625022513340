import { NavLink, Outlet } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SubNavigation({ tabs }) {
  return (
    <div className="flex flex-col gap-5 bg-white p-5 rounded-xl">
      <div className="border-b border-gray-200">
        <div className="sm:flex sm:items-baseline">
          <div className="mt-4 sm:ml-10 sm:mt-0">
            <nav className="-mb-px flex space-x-8 overflow-x-auto">
              {tabs.map((tab) => (
                <NavLink
                  key={tab.label}
                  to={tab.href}
                  className={(props) => {
                    return classNames(
                      props.isActive
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium cursor-pointer"
                    );
                  }}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.label}
                </NavLink>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
