import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import appReducer from "../reducers/appReducer";
import { gymListReducer } from "../reducers/gymListReducer";
import { userInfoReducer } from "../reducers/userInfoReducer";
import { userSaga } from "../sagas/appSaga";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    app: appReducer,
    userInfo: userInfoReducer,
    gyms: gymListReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

// then run the saga
sagaMiddleware.run(userSaga);

export default store;
