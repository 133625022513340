import { getTokenFromLocalStorage, logout } from "../utils/helpers";

const call = async (url, method, data) => {
  let isMultiPartFormData;
  let formData;
  if (data?.image instanceof File) {
    isMultiPartFormData = true;
    formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
  }
  const res = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
    method: method,
    headers: {
      ...(!isMultiPartFormData ? { "content-type": "application/json" } : {}),
      Authorization: `Bearer ${getTokenFromLocalStorage()}`,
    },
    ...(data ? { body: formData ?? JSON.stringify(data) } : {}),
  });
  const resData = await res.json();

  if ([401].includes(resData.status)) {
    logout();
    return;
  }

  if (![200, 201].includes(resData.status)) {
    // console.log("line 15 ", resData.status);
    throw new Error("Request failed", {
      cause: { status: resData.status, message: resData.message },
    });
  }

  return resData;
};

export function get(url) {
  return call(url, "GET");
}

export function post(url, data) {
  return call(url, "POST", data);
}

export function patch(url, data) {
  return call(url, "PATCH", data);
}

export function deleteReq(url, data) {
  return call(url, "DELETE", data);
}
