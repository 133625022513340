import { Button } from "antd";
import { useDispatch } from "react-redux";
import { setOnBoardingModalStatus } from "../../redux/actions/app";

const AddNewGym = () => {
  const dispatch = useDispatch();
  const handleGymClick = () => {
    dispatch(setOnBoardingModalStatus(true));
  };
  return (
    <Button onClick={handleGymClick} type="primary">
      Add New Gym
    </Button>
  );
};

export default AddNewGym;
