import { Modal } from "antd";
import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { wellFormedImageUrl } from "../../../utils/helpers";
import { useCreateWorkoutRoutineContext } from "./CreateWorkoutRoutineContext";
import { CloseButton } from "./ExerciseDays";

export const AddedExerciseModal = ({ isModalOpen, setIsModalOpen }) => {
  const { dayId, routineId } = useParams();
  const {
    addedExercisesData,
    deleteExerciseFromRoutine,
    addExercise,
    isAddExerciseInProgress,
    isRemoveInProgress,
  } = useCreateWorkoutRoutineContext();

  const exerciseData = useMemo(
    () => addedExercisesData.filter((item) => item.dayId === Number(dayId)),
    [addedExercisesData, dayId]
  );

  const hasData = useMemo(() => exerciseData.length > 0, [exerciseData.length]);

  const loader = (
    <div className="flex flex-col gap-3 justify-center">
      {Array.from(Array(exerciseData.length || 3).keys()).map((row, index) => {
        return (
          <Skeleton
            baseColor="#FFFFFF"
            highlightColor="#f3f4f6"
            key={index}
            width={"100%"}
            height={70}
            className="roundex-xl"
          />
        );
      })}
    </div>
  );

  return (
    <Modal
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <div className="flex flex-col p-3 relative !z-20 w-full sm:w-[480px]">
        <div className="flex justify-between gap-3 h-[50px] items-center">
          <div className="text-xl font-semibold">Added Exercises</div>
          {/* <CloseButton onClick={setIsModalOpen} /> */}
        </div>
        <div className="flex flex-col gap-3 overflow-y-scroll max-h-[500px] mt-3">
          {!hasData && !isRemoveInProgress && (
            <div className="flex justify-center items-center h-[200px]">
              No Exercise Added
            </div>
          )}
          {isRemoveInProgress && loader}
          {!isRemoveInProgress &&
            exerciseData.map((data, index) => {
              const { Exercise: exercise } = data;
              return (
                <div
                  className="flex justify-between pr-3 bg-slate-50 rounded-xl gap-2 items-center"
                  key={index}
                >
                  <div className="flex gap-3 items-center">
                    <img
                      src={
                        exercise.image
                          ? `${wellFormedImageUrl(exercise.image)}`
                          : undefined
                      }
                      alt="exercise"
                      className="roundex-xl object-cover h-[70px] w-[70px] rounded-l-xl"
                    />
                    <div className="flex flex-col gap-2 ">
                      <div className="text-base font-medium flex flex-wrap">
                        {exercise.title}
                      </div>
                    </div>
                  </div>
                  <CloseButton
                    onClick={() =>
                      deleteExerciseFromRoutine({
                        routineId: Number(routineId),
                        exerciseId: exercise.id,
                        dayId: Number(dayId),
                      })
                    }
                  />
                </div>
              );
            })}
        </div>
      </div>
    </Modal>
  );
};

const customStyles = {
  overlay: {
    backgroundColor: "rgba(255, 255, 255, 0.25)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "320px",
    maxWidth: "800px",
    // maxHeight: '500px',
    backgroundColor: "#2c2c2e",
    borderRadius: "16px",
    border: "none",
    padding: "0px",
    zIndex: 999,
  },
};
