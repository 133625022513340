import { useCallback } from "react";

import useFetchAsync from "../../../hooks/useFetchAsync";
import useSwrFetch from "../../../hooks/useSwrFetch";
import { deleteReq, post } from "../../../http-clients/clients";

export const usePostRoutineSet = () => {
  const addSets = useCallback((data) => {
    return post(`/workout-routine/exercise-logs`, { data });
  }, []);

  return useFetchAsync(addSets);
};

export const useGetRoutineSet = ({
  routineId,
  dayId,
  exerciseId,
  memberId,
}) => {
  return useSwrFetch(
    `/workout-routine/exercise-logs/${routineId}/${dayId}/${exerciseId}/${memberId}`
  );
};

export const useRemoveRoutineSet = () => {
  const addSets = useCallback((data) => {
    return deleteReq(`/workout-routine/remove-set`, { ...data });
  }, []);

  return useFetchAsync(addSets);
};

//it takes array of number and deletes history of one routine
export const useRemoveRoutineHistory = () => {
  const addSets = useCallback(({ data, routineId }) => {
    return deleteReq(`/workout-routine/remove-day-history`, {
      data,
      routineId,
    });
  }, []);

  return useFetchAsync(addSets);
};
