import { useCallback } from "react";
import useFetchAsync from "../../../hooks/useFetchAsync";
import useSwrFetch from "../../../hooks/useSwrFetch";
import { deleteReq, post } from "../../../http-clients/clients";

export const useCreateWrokoutRoutineName = () => {
  const createName = useCallback((data) => {
    return post("/workout-routine/create", { ...data });
  }, []);
  return useFetchAsync(createName);
};

export const useGetAllExercisesList = ({ searchText = "", bodyParts = [] }) => {
  const mapperFun = useCallback((res) => {
    const mappedData = res.data.map((item) => {
      const { BodyParts } = item;
      const mappedBodyParts = BodyParts.map((bodyPart) => {
        return bodyPart;
      });
      return { ...item, bodyParts: mappedBodyParts };
    });
    return mappedData;
  }, []);
  return useSwrFetch(
    `/exercise/get-all?search=${
      searchText ?? ""
    }&bodyParts=${bodyParts.toString()}`,
    mapperFun
  );
};

const pendingForms = {};

export const useGetAllExercisesListPostReq = ({
  searchText = "",
  start = 0,
}) => {
  const getExercise = useCallback(
    (data) => {
      const reqId = "postAllExercise";
      const previousController = pendingForms[reqId];

      if (previousController) {
        previousController.abort();
      }
      const controller = new AbortController();
      pendingForms[reqId] = controller;

      return post(
        `/exercise/get-all?start=${start}&length=10&search=${searchText}`,
        data,
        controller.signal
      );
    },
    [searchText, start]
  );
  return useFetchAsync(getExercise, true);
};

export const useAddRoutineExercise = () => {
  const createExercise = useCallback((data) => {
    return post("/workout-routine/routine-exercise", { ...data });
  }, []);
  return useFetchAsync(createExercise);
};

export const useGetAddedExercises = (routineId) => {
  return useSwrFetch(`/workout-routine/routine-exercise/${routineId}`);
};

export const useRemoveAddedRoutineExercise = () => {
  const createExercise = useCallback((data) => {
    return deleteReq("/workout-routine/routine-exercise", { ...data });
  }, []);
  return useFetchAsync(createExercise);
};

export const useRemoveAllDayExercises = () => {
  const removeExercises = useCallback((data) => {
    return deleteReq("/workout-routine/routine-exercises", { ...data });
  }, []);
  return useFetchAsync(removeExercises);
};
