import { Input as AntInput } from "antd";
import classNames from "classnames";

const Input = ({
  label,
  type,
  name,
  id,
  error,
  onChange,
  disabled = false,
  className,
  value,
}) => {
  return (
    <ElementWithLabel
      className={className}
      label={label}
      name={name}
      error={error}
    >
      <AntInput
        disabled={disabled}
        onChange={onChange}
        type={type}
        id={id}
        value={value}
        placeholder={label}
      />
    </ElementWithLabel>
  );
};

export default Input;

export const ElementWithLabel = ({
  className,
  children,
  label,
  name,
  error,
}) => {
  return (
    <div className={classNames("flex flex-col w-full", className)}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="flex flex-col gap-2 mt-2">
        {children}
        {error && <span className="text-xs text-red-500">{error}</span>}
      </div>
    </div>
  );
};
