import { useNavigate } from "react-router-dom";
import TabHeader from "../../../components/layouts/TabHeader";
import AddRoutineName from "./AddRoutineName";

const CreateWorkout = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-5">
      <TabHeader
        label="Add Workout Name"
        showBackBtn
        //   onReload={refreshStaffList}
        //   onSearch={(text) => setSearchText(text)}
      />

      <div className="flex justify-center h-full w-full rounded-[16px] bg-white">
        <div className="flex justify-center flex-col gap-3 mt-6 w-full sm:max-w-[400px] p-2">
          <AddRoutineName />
        </div>
      </div>
    </div>
  );
};

export default CreateWorkout;
