import { useCallback } from "react";

import useFetchAsync from "../../hooks/useFetchAsync";
import useSwrFetch from "../../hooks/useSwrFetch";
import { deleteReq, post } from "../../http-clients/clients";

export const useGetAllWorkoutRoutines = (data) => {
  const { userId } = data ?? {};
  return useSwrFetch(
    userId ? `/workout-routine/${userId}` : `/workout-routine`
  );
};

export const useDeleteWorkoutRoutine = () => {
  const deleteRoutine = useCallback((id) => {
    return deleteReq(`/workout-routine`, { id: id });
  }, []);
  return useFetchAsync(deleteRoutine);
};

export const useEditWorkoutRoutineName = () => {
  const editName = useCallback(({ workoutRoutineId, name }) => {
    return post(`/workout-routine/edit-name`, { workoutRoutineId, name });
  }, []);

  return useFetchAsync(editName);
};
