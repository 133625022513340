import { Button, Empty, Typography } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSetActiveTab from "../../components/hooks/useSetActiveTab";
import TabHeader from "../../components/layouts/TabHeader";
import { TAB_NAMES } from "../../components/layouts/menuNavigation";
import AssignRoutineModal from "../members/routines/AssignRoutineModal";
import WorkoutRoutineContextProvider, {
  useWorkoutRoutineContext,
} from "./WorkoutRoutineContext";
import RoutinesList from "./added-workout-routines/RoutinesList";

const Routines = () => {
  const { memberId } = useParams();
  useSetActiveTab(memberId ? TAB_NAMES.MEMBERS : TAB_NAMES.WORKOUTS);
  const navigate = useNavigate();
  return (
    <WorkoutRoutineContextProvider memberId={memberId}>
      <RoutinesContent />
    </WorkoutRoutineContextProvider>
  );
};

export default Routines;

const RoutinesContent = () => {
  const navigate = useNavigate();
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const {
    hasWorkoutRoutines,
    inProgress,
    useAssignmentFlow,
    refreshRoutineList,
  } = useWorkoutRoutineContext();

  const handleCreateClick = useCallback(
    () =>
      useAssignmentFlow
        ? setIsAssignModalOpen(true)
        : navigate("/workouts/add"),
    [navigate, useAssignmentFlow]
  );

  const buttonLabel = useMemo(
    () => (useAssignmentFlow ? "Assign Workout" : "Create Workout"),
    [useAssignmentFlow]
  );

  const handleOnCancel = useCallback(() => {
    setIsAssignModalOpen(false);
    refreshRoutineList();
  }, [refreshRoutineList]);

  return (
    <>
      {" "}
      {isAssignModalOpen && (
        <AssignRoutineModal
          isOpen={isAssignModalOpen}
          onCancel={handleOnCancel}
          footer={null}
        />
      )}
      <div className="flex flex-col gap-5">
        <TabHeader
          label="Workout Routines"
          showBackBtn
          buttonLabel={buttonLabel}
          onButtonClick={handleCreateClick}
          //   onReload={refreshStaffList}
          //   onSearch={(text) => setSearchText(text)}
        />
        {inProgress || hasWorkoutRoutines ? (
          <RoutinesList />
        ) : (
          <div className="flex justify-center p-4 bg-slate-50 rounded-xl">
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 60,
                justifyContent: "center",
                display: "flex",
              }}
              description={
                <Typography.Text>
                  {useAssignmentFlow
                    ? "This user doesn't have any workout routine assigned."
                    : "You don't have any Workout Routines yet."}
                </Typography.Text>
              }
            >
              <Button type="primary" onClick={handleCreateClick}>
                {buttonLabel}
              </Button>
            </Empty>
          </div>
        )}
      </div>
    </>
  );
};
