import dayjs from "dayjs";
import { groupBy, mapValues } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../components/modals/DeleteModal";
import { useNotification } from "../../../components/notification/NotificationContext";
import { HorizontalSeparator } from "../../members/create/AddMember";
import { useGetRoutineSet, useRemoveRoutineHistory } from "./api-client";

const WorkoutHistory = () => {
  const navigate = useNavigate();
  const { successNotification, errorNotification } = useNotification();
  const { dayId, routineId, exerciseId, memberId } = useParams();
  const [dataToOperate, setDataToOperate] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { data = [], mutate } = useGetRoutineSet({
    dayId: Number(dayId),
    routineId: Number(routineId),
    exerciseId: Number(exerciseId),
    userId: Number(memberId),
  });

  const {
    handleExecution: removeHistory,
    isSuccess,
    error,
  } = useRemoveRoutineHistory();

  const logsData = useMemo(() => data.data ?? [], [data]);

  const mappedData = useMemo(() => {
    const removeTime = logsData.map((item) => {
      return {
        ...item,
        logDate: dayjs(item.logDate ?? item.createdAt).startOf("day"),
      };
    });
    // return mapValues(groupBy(removeTime ?? [], 'createdAt'), (clist) =>
    //   clist.map((car) => omit(car, 'createdAt'))
    // );
    return mapValues(groupBy(removeTime ?? [], "logDate"));
  }, [logsData]);

  useEffect(() => {
    if (isSuccess) {
      mutate();
      successNotification("Removed data.");
      setIsDeleteModalOpen(false);
      setDataToOperate([]);
    }
    if (error) {
      errorNotification();
    }
  }, [error, errorNotification, isSuccess, mutate, successNotification]);

  const handleDelete = useCallback(() => {
    removeHistory({ data: dataToOperate, routineId: Number(routineId) });
  }, [dataToOperate, removeHistory, routineId]);

  if (Object.keys(mappedData).length === 0) {
    return (
      <div className="flex flex-col items-center justify-center bg-slate-50 h-full w-full rounded-[16px] mt-6 p-4">
        No History Yet.
      </div>
    );
  }

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        isLoading={false}
        onSubmit={handleDelete}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 w-full pb-[80px]">
        {Object.keys(mappedData).map((item) => {
          const idsOfSets = mappedData[item].map((item) => item.id);
          return (
            <div
              className="flex flex-col shadow-xl rounded-xl gap-3 bg-slate-50 h-full"
              key={item.id}
            >
              <div className="flex justify-between gap-3 p-3 items-center">
                <div className="text left text-xl font-bold truncate">
                  {dayjs(item).format("dddd, MMMM D YYYY")}
                </div>
                {/* <div className="flex gap-3 items-center">
                  <EditButton
                    onClick={() =>
                      navigate(
                        `/log-exercise/${Number(routineId)}/${Number(
                          dayId
                        )}/${Number(exerciseId)}/add/${dayjs(item).unix()}`
                      )
                    }
                  />
                  <CloseButton
                    onClick={() => [
                      setDataToOperate(idsOfSets),
                      setIsDeleteModalOpen(true),
                    ]}
                  />
                </div> */}
              </div>
              <HorizontalSeparator />
              <div className="flex flex-col p-3">
                <table>
                  <thead className="flex justify-between">
                    <td className="text-left font-bold">Set</td>
                    <td className="text-center font-bold">Reps</td>
                    <td className="text-right font-bold">Weight</td>
                  </thead>
                </table>
                {mappedData[item].map((item, index) => {
                  return (
                    <div
                      className="flex justify-between items-center"
                      key={index}
                    >
                      <div>{index + 1}</div>
                      <div>{item.reps}</div>
                      <div>{item.weight} KG</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default WorkoutHistory;
