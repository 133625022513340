export const bodyPartOptions = [
  {
    label: "Chest",
    value: 1,
  },
  {
    label: "Back",
    value: 2,
  },
  {
    label: "Bicep",
    value: 3,
  },
  {
    label: "Tricep",
    value: 4,
  },
  {
    label: "Shoulder",
    value: 5,
  },
  {
    label: "Leg",
    value: 6,
  },
  {
    label: "Traps",
    value: 7,
  },
  {
    label: "Forearm",
    value: 8,
  },
];

export const equipmentOptions = [
  {
    label: "Bar",
    value: 1,
  },
  {
    label: "Dumbbell",
    value: 2,
  },
  {
    label: "Smith Machine",
    value: 3,
  },
  {
    label: "Cable",
    value: 4,
  },
  {
    label: "Machine",
    value: 5,
  },
  {
    label: "Body Weight",
    value: 6,
  },
];

export const positionOptions = [
  {
    label: "Standing",
    value: 1,
  },
  {
    label: "Sitting",
    value: 2,
  },
  {
    label: "Lying",
    value: 3,
  },
];

export const gripOptions = [
  {
    label: "Overhand (Pronated)",
    value: 1,
  },
  {
    label: "Underhand (Supinated)",
    value: 2,
  },
  {
    label: "Neutral (Hammer)",
    value: 3,
  },
];

export const limbOptions = [
  {
    label: "Single Arm",
    value: 1,
  },
  {
    label: "Double Arm",
    value: 2,
  },
  {
    label: "Single Leg",
    value: 3,
  },
  {
    label: "Double Leg",
    value: 4,
  },
];

export const EXERCISE_FILTERS = [
  {
    label: "Body Parts",
    value: "bodyParts",
    options: bodyPartOptions,
  },
  {
    label: "Equipment",
    value: "equipments",
    options: equipmentOptions,
  },
  {
    label: "Position",
    value: "position",
    options: positionOptions,
  },
  {
    label: "Grip",
    value: "grip",
    options: gripOptions,
  },
  {
    label: "Limb",
    value: "limb",
    options: limbOptions,
  },
];

export const routineGoalOptions = [
  {
    label: "Muscle Building",
    value: 1,
  },
  {
    label: "Fat Loss",
    value: 2,
  },
];

export const routineTypeOptions = [
  {
    label: "Push Pull Leg",
    value: 1,
  },
  {
    label: "Bro Split",
    value: 2,
  },
];

export const experienceOptions = [
  {
    label: "Beginner",
    value: 1,
  },
  {
    label: "Intermediate",
    value: 2,
  },
  {
    label: "Advanced",
    value: 3,
  },
  {
    label: "Pro",
    value: 4,
  },
];

export const statusOptions = [
  {
    label: "Active",
    value: 1,
  },
  {
    label: "Expired",
    value: 2,
  },
];

export const genderOptions = [
  {
    value: 1,
    label: "Male",
  },
  {
    value: 2,
    label: "Female",
  },
  {
    value: 3,
    label: "Others",
  },
];
