import { Button } from "antd";
import { wellFormedImageUrl } from "../../../utils/helpers";

const ExerciseCard = ({
  image,
  title,
  bodyParts,
  onExerciseAdd,
  isExerciseAdded = false,
  onExerciseRemove,
  isAddInProgress,
}) => {
  return (
    <div className="rounded-xl relative overflow-hidden">
      <img
        src={image ? `${wellFormedImageUrl(image)}` : null}
        alt="exercise"
        className="roundex-xl object-cover w-full h-full"
      />
      <div className="absolute flex flex-col justify-between h-full w-full inset-0 bg-gradient-to-b from-transparent to-slate-800	p-3	">
        <div className="flex justify-end gap-3">
          <Button
            onClick={() =>
              isExerciseAdded ? onExerciseRemove() : onExerciseAdd()
            }
            loading={isAddInProgress}
          >
            {isExerciseAdded ? "Remove" : "Add"}
          </Button>
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-sm sm:text-xl font-semibold text-white">
            {title}
          </div>
          {/* <div className="flex gap-3 items-center flex-wrap">
            {bodyParts?.map((bodyPart) => {
              return (
                <div
                  key={bodyPart.id}
                  className="border-2 border-[#848484] bg-[#2c2c2e] p-2 rounded-2xl text-sm"
                >
                  {bodyPart.title}
                </div>
              );
            })}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ExerciseCard;
