import dayjs from "dayjs";
import { groupBy, mapValues } from "lodash";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetRoutineSet } from "./api-client";

export const ExerciseStats = () => {
  const { dayId, routineId, exerciseId, memberId } = useParams();
  const { data } = useGetRoutineSet({
    dayId: Number(dayId),
    routineId: Number(routineId),
    exerciseId: Number(exerciseId),
    userId: Number(memberId),
  });

  const allData = useMemo(() => data?.data ?? [], [data?.data]);
  const dataFotThisExercise = useMemo(
    () => allData.filter((item) => item.exerciseId === Number(exerciseId)),
    [allData, exerciseId]
  );

  const highestLiftedWeight = useMemo(
    () =>
      dataFotThisExercise.sort(
        (item1, item2) => Number(item2.weight) - Number(item1.weight)
      )?.[0]?.weight ?? 0,
    [dataFotThisExercise]
  );

  const totalSets = useMemo(
    () => dataFotThisExercise.length ?? 0,
    [dataFotThisExercise.length]
  );

  const totalReps = useMemo(
    () => dataFotThisExercise.reduce((acc, curr) => acc + curr.reps, 0),
    [dataFotThisExercise]
  );

  const totalWeight = useMemo(
    () =>
      dataFotThisExercise.reduce((acc, curr) => acc + Number(curr.weight), 0),
    [dataFotThisExercise]
  );

  const mappedData = useMemo(() => {
    const removeTime = dataFotThisExercise.map((item) => {
      return {
        ...item,
        logDate: dayjs(item.logDate ?? item.createdAt).startOf("day"),
      };
    });

    return mapValues(groupBy(removeTime ?? [], "logDate"));
  }, [dataFotThisExercise]);

  const totalDays = useMemo(() => Object.keys(mappedData).length, [mappedData]);
  return (
    <div className="flex w-full h-fit ">
      <div className="grid grid-cols-2 lg:grid-cols-5 gap-4 mb-4 w-full mt-4">
        <div className="flex flex-col justify-center items-center h-[100px] md:h-[150px] bg-slate-50 shadow-xl rounded-xl gap-3 p-2 first:col-span-2 lg:first:col-span-1">
          <div className="text-md font-medium">Highest Lifted Weight</div>
          <div className="text-2xl font-bold text-[#F6A800]">
            {highestLiftedWeight}
            <span className="text-sm"> Kg</span>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-[100px] md:h-[150px] bg-slate-50 shadow-xl rounded-xl gap-3 p-2">
          <div className="text-md font-medium">Total Weight</div>
          <div className="text-2xl font-bold text-[#F6A800]">
            {totalWeight}
            <span className="text-sm"> Kg</span>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center h-[100px] md:h-[150px] bg-slate-50 shadow-xl rounded-xl gap-3 p-2">
          <div className="text-md font-medium">Days of Lifting</div>
          <div className="text-2xl font-bold text-[#F6A800]">{totalDays}</div>
        </div>
        <div className="flex flex-col justify-center items-center h-[100px] md:h-[150px] bg-slate-50 shadow-xl rounded-xl gap-3 p-2">
          <div className="text-md font-medium">Total Sets</div>
          <div className="text-2xl font-bold text-[#F6A800]">{totalSets}</div>
        </div>
        <div className="flex flex-col justify-center items-center h-[100px] md:h-[150px] bg-slate-50 shadow-xl rounded-xl gap-3 p-2">
          <div className="text-md font-medium">Total Reps</div>
          <div className="text-2xl font-bold text-[#F6A800]">{totalReps}</div>
        </div>
      </div>
    </div>
  );
};
