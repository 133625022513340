import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../redux/actions/app";

const useSetActiveTab = (tabName) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveTab(tabName));
  }, [dispatch, tabName]);
};

export default useSetActiveTab;
