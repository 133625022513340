import { EditOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HorizontalSeparator } from "../../members/create/AddMember";
import MoreOptionsBtn from "../MoreOptionsBtn";
import { useWorkoutRoutineContext } from "../WorkoutRoutineContext";

const RoutineCard = ({ id, title, createdAt, workingDays }) => {
  const navigate = useNavigate();
  const { memberId } = useParams();
  const { handleDeleteRoutineModal, setIdToOperate, setIsEditNameModalOpen } =
    useWorkoutRoutineContext();

  const options = useMemo(() => {
    return [
      // {
      //   label: 'Edit',
      //   onClick: () => navigate(`/workout-day/${id}`),
      // },
      {
        label: "Edit Name",
        onClick: () => setIsEditNameModalOpen(true),
      },
      {
        label: "Delete",
        onClick: handleDeleteRoutineModal,
        className: "text-red-500",
      },
    ];
  }, [handleDeleteRoutineModal, setIsEditNameModalOpen]);

  const isStartWorkoutDisabled = useMemo(
    () => workingDays === 0,
    [workingDays]
  );

  return (
    <div className="flex flex-col shadow-xl p-3 rounded-xl gap-3 justify-between bg-slate-50">
      <div className="flex gap-3 justify-between items-center">
        <div className="font-bold text-[16px]">{title}</div>
        {!memberId && (
          <div className="flex gap-2 items-center">
            <div
              className="flex justify-center items-center h-[36px] w-[36px] rounded-full bg-white cursor-pointer"
              onClick={() => navigate(`/workout-day/${id}`)}
            >
              <EditOutlined className="!h-[12px] !w-[12px]" />
            </div>
            <MoreOptionsBtn
              onClick={() => setIdToOperate(id)}
              options={options}
            />
          </div>
        )}
      </div>
      <HorizontalSeparator />
      <div className="flex flex-col gap-3">
        <div className="flex justify-between text-xs">
          <div className="text-bold">Working Days</div>
          <div className="text-semibold">{workingDays}</div>
        </div>
        <div className="flex justify-between text-xs">
          <div className="text-bold">Created At</div>
          <div className="text-semibold">{createdAt}</div>
        </div>
      </div>
      <HorizontalSeparator />
      <div className="flex gap-3 w-full">
        {/* <Button
          text="View History"
          onClick={() => navigate(`/workouts/history/${id}`)}
          disabled={isStartWorkoutDisabled}
          tooltip={
            isStartWorkoutDisabled
              ? 'This rouine does not have any Added Exercise...Please add some exercise in it..in order to start this workout'
              : undefined
          }
          className="w-full"
        /> */}
        <Tooltip
          title={
            isStartWorkoutDisabled
              ? "This rouine does not have any Added Exercise...Please add some exercise in it..in order to start this workout"
              : undefined
          }
        >
          <Button
            onClick={() =>
              navigate(
                memberId
                  ? `/start-workout/${id}/${memberId}`
                  : `/start-workout/${id}`
              )
            }
            disabled={isStartWorkoutDisabled}
            className="w-full"
          >
            View History
          </Button>
        </Tooltip>
      </div>

      {/* <Button text="View History" version={ButtonVersion.TWO} /> */}
    </div>
  );
};

export default RoutineCard;
