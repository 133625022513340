import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { post } from "../../../http-clients/clients";

const ExercsiseFilterContext = createContext({});
const pendingForms = {};
export const ExercsiseFilterContextProvider = ({ children, filterOptions }) => {
  const [searchText, setSearchText] = useState("");
  const [start, setStart] = useState(0);
  const [totalCount, setTotalCount] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [exerciseData, setExerciseData] = useState([]);

  // Helper function to remove duplicates based on a unique key
  const removeDuplicates = (arr, key) => {
    return arr.reduce((acc, current) => {
      const x = acc.find((item) => item[key] === current[key]);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  };

  const executeGetExercises = useCallback(
    async (filters) => {
      try {
        setIsLoading(true);
        const reqId = "postAllExercise";
        const previousController = pendingForms[reqId];
        if (previousController) {
          previousController.abort();
        }
        const controller = new AbortController();
        pendingForms[reqId] = controller;

        const res = await post(
          `/exercise/get-all?start=${start}&length=10&search=${searchText}`,
          filters ?? {},
          controller.signal
        );
        setTotalCount(res.data.count);
        if (res.data.count === 0) {
          setExerciseData([]);
          return;
        }

        //@ts-ignore
        setExerciseData((prev) => {
          return removeDuplicates([...prev, ...res.data.rows], "id");

          return [...prev, ...res.data.rows];
        });
      } catch (err) {
        console.log("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    },
    [searchText, start]
  );

  useEffect(() => {
    executeGetExercises();
  }, [executeGetExercises, searchText]);

  const handleSubmit = useCallback(() => {
    if (exerciseData.length > 0) {
      setStart(0);
      setExerciseData([]);
    }

    executeGetExercises(appliedFilters);
  }, [appliedFilters, executeGetExercises, exerciseData.length]);

  const handleSearch = useCallback((text) => {
    setStart(0);
    setExerciseData([]);
    setSearchText(() => {
      return text;
    });
  }, []);

  // const handleAppyFilter = useCallback(() => {
  //   setStart(0);
  //   setExerciseData([]);
  //   executeGetExercises();
  // }, [executeGetExercises]);

  return (
    <ExercsiseFilterContext.Provider
      value={{
        appliedFilters,
        setAppliedFilters,
        selectedFilter,
        setSelectedFilter,
        inProgress: isLoading,
        exerciseList: exerciseData,
        searchText,
        setSearchText,
        setStart,
        handleSubmit,
        handleExecution: executeGetExercises,
        start,
        setExerciseData,
        totalCount,
        handleSearch,
        filterOptions,
      }}
    >
      {children}
    </ExercsiseFilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(ExercsiseFilterContext);
};
