import DeleteModal from "../../../components/modals/DeleteModal";

const DeleteRoutineModal = ({
  isDeleteInProgress,
  isModalOpen,
  setIsModalOpen,
  onDelete,
}) => {
  return (
    <DeleteModal
      isOpen={isModalOpen}
      isLoading={isDeleteInProgress}
      onSubmit={onDelete}
      onCancel={() => setIsModalOpen(false)}
    />
  );
};

export default DeleteRoutineModal;
