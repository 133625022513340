import TabHeader from "../../../components/layouts/TabHeader";
import SubNavigation from "../../gym/NestedRoutes";

const LogWorkouts = () => {
  return (
    <div className="flex flex-col gap-5 ">
      <TabHeader
        label={"Routine Stats"}
        //onBackClick={() => navigate(`/start-workout/${routineId}`)}
      />
      <SubNavigation tabs={tabs} />
    </div>
  );
};

export default LogWorkouts;

const tabs = [
  {
    label: "Stats",
    name: "stats",
    href: `stats`,
  },
  {
    label: "Histroy",
    name: "history",
    href: `history`,
  },
  {
    label: "Performance",
    name: "performance",
    href: `performance`,
  },
];
