import { Button, Select } from "antd";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { number, object, string } from "yup";
import Input, { ElementWithLabel } from "../../../components/inputs/Input";
import { useNotification } from "../../../components/notification/NotificationContext";
import { setActiveGym } from "../../../redux/actions/app";
import { getActiveGymId, getActiveGymInfo } from "../../../redux/selectors/app";
import cities from "../../../utils/cities.json";
import states from "../../../utils/states.json";
import { useCreateGym, useUpdateGym } from "../apis";

const FORM_FIELDS = {
  NAME: "name",
  ADDRESS: "address",
  CITY: "city",
  STATE: "state",
  COUNTRY: "country",
  POSTAL_CODE: "postalCode",
  MOBILE: "mobile",
  ALTERNATE_MOBILE: "alternateMobile",
  EMAIL: "email",
};

export default function GymInfo({ shouldUseInitialValues = true }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorNotification, successNotification } = useNotification();
  const {
    inProgress,
    isSuccess,
    error,
    execute: createGym,
    data,
  } = useCreateGym();

  const {
    inProgress: isUpdateInProgress,
    isSuccess: isUpdateSuccess,
    error: updateErr,
    execute: updateGym,
  } = useUpdateGym();

  const activeGymId = useSelector(getActiveGymId);
  const currentGymInfo = useSelector((state) =>
    getActiveGymInfo(state, activeGymId)
  );

  const initialValues = useMemo(() => {
    if (!shouldUseInitialValues) {
      return {
        [FORM_FIELDS.NAME]: "",
        [FORM_FIELDS.ADDRESS]: "",
        [FORM_FIELDS.CITY]: "",
        [FORM_FIELDS.STATE]: "",
        [FORM_FIELDS.COUNTRY]: "IN",
        [FORM_FIELDS.POSTAL_CODE]: "",
        [FORM_FIELDS.MOBILE]: "",
        [FORM_FIELDS.ALTERNATE_MOBILE]: "",
        [FORM_FIELDS.EMAIL]: "",
      };
    }
    return {
      [FORM_FIELDS.NAME]: currentGymInfo?.name ?? "",
      [FORM_FIELDS.ADDRESS]: currentGymInfo?.address ?? "",
      [FORM_FIELDS.CITY]: currentGymInfo?.city ?? "",
      [FORM_FIELDS.STATE]: currentGymInfo?.state ?? "",
      [FORM_FIELDS.COUNTRY]: currentGymInfo?.country ?? "IN",
      [FORM_FIELDS.POSTAL_CODE]: currentGymInfo?.postalCode ?? "",
      [FORM_FIELDS.MOBILE]: currentGymInfo?.mobile ?? "",
      [FORM_FIELDS.ALTERNATE_MOBILE]: currentGymInfo?.alternateMobile ?? "",
      [FORM_FIELDS.EMAIL]: currentGymInfo?.email ?? "",
    };
  }, [
    currentGymInfo?.address,
    currentGymInfo?.alternateMobile,
    currentGymInfo?.city,
    currentGymInfo?.country,
    currentGymInfo?.email,
    currentGymInfo?.mobile,
    currentGymInfo?.name,
    currentGymInfo?.postalCode,
    currentGymInfo?.state,
    shouldUseInitialValues,
  ]);

  const { values, errors, handleSubmit, handleChange, touched, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: object({
        [FORM_FIELDS.NAME]: string().required("This is a Required field."),
        [FORM_FIELDS.ADDRESS]: string().required("This is a Required field."),
        [FORM_FIELDS.CITY]: string().required("This is a Required field."),
        [FORM_FIELDS.STATE]: string().required("This is a Required field."),
        [FORM_FIELDS.COUNTRY]: string().required("This is a Required field."),
        [FORM_FIELDS.POSTAL_CODE]: string().required(
          "This is a Required field."
        ),
        [FORM_FIELDS.MOBILE]: number().required("This is a Required field."),
        [FORM_FIELDS.EMAIL]: string().email("Please enter valid email."),
      }),
      onSubmit: (data) => {
        if (initialValues?.name) {
          updateGym(data);
          return;
        }
        createGym(data);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setActiveGym(data?.data?.gymId));
      successNotification("Gym Created.");
    }
  }, [data?.data?.gymId, dispatch, isSuccess, navigate, successNotification]);

  useEffect(() => {
    if (isUpdateSuccess) {
      successNotification("Data updated.");
    }
  }, [isUpdateSuccess, successNotification]);

  useEffect(() => {
    if (error || updateErr) {
      errorNotification("Something went wrong.");
    }
  }, [error, errorNotification, updateErr]);

  const formDisabled = useMemo(
    () => inProgress || isUpdateInProgress,
    [inProgress, isUpdateInProgress]
  );

  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <form className="grid lg:gap-x-12 xl:gap-x-16" onSubmit={handleSubmit}>
          <div className="flex flex-col gap-3">
            <div className="flex justify-between items-center gap-3">
              <div className="font-bold">Enter Gym Details</div>
              <Button type="primary" htmlType="submit" loading={formDisabled}>
                {"Submit"}
              </Button>
            </div>
            <Input
              id={FORM_FIELDS.NAME}
              label="Enter Gym Name"
              name={FORM_FIELDS.NAME}
              value={values[FORM_FIELDS.NAME]}
              error={
                errors[FORM_FIELDS.NAME] &&
                touched[FORM_FIELDS.NAME] &&
                errors[FORM_FIELDS.NAME]
              }
              onChange={handleChange}
              disabled={formDisabled}
            />

            <Input
              id={FORM_FIELDS.ADDRESS}
              label="Enter Gym ADDRESS"
              name={FORM_FIELDS.ADDRESS}
              value={values[FORM_FIELDS.ADDRESS]}
              error={
                errors[FORM_FIELDS.ADDRESS] &&
                touched[FORM_FIELDS.ADDRESS] &&
                errors[FORM_FIELDS.ADDRESS]
              }
              onChange={handleChange}
              disabled={formDisabled}
            />

            <div className="flex gap-3 w-full">
              <ElementWithLabel
                label="City"
                name={FORM_FIELDS.CITY}
                error={
                  errors[FORM_FIELDS.CITY] &&
                  touched[FORM_FIELDS.CITY] &&
                  errors[FORM_FIELDS.CITY]
                }
              >
                <Select
                  showSearch
                  name={FORM_FIELDS.CITY}
                  placeholder="Select City"
                  optionFilterProp="children"
                  value={values[FORM_FIELDS.CITY]}
                  onChange={(value) => setFieldValue(FORM_FIELDS.CITY, value)}
                  // onSearch={() => {}}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={cities}
                  disabled={formDisabled}
                />
              </ElementWithLabel>
              <Input
                id={FORM_FIELDS.POSTAL_CODE}
                label="Postal Code"
                name={FORM_FIELDS.POSTAL_CODE}
                value={values[FORM_FIELDS.POSTAL_CODE]}
                error={
                  errors[FORM_FIELDS.POSTAL_CODE] &&
                  touched[FORM_FIELDS.POSTAL_CODE] &&
                  errors[FORM_FIELDS.POSTAL_CODE]
                }
                onChange={handleChange}
                disabled={formDisabled}
              />
            </div>

            <div className="flex gap-3 w-full">
              <ElementWithLabel
                label="State"
                name={FORM_FIELDS.STATE}
                error={
                  errors[FORM_FIELDS.STATE] &&
                  touched[FORM_FIELDS.STATE] &&
                  errors[FORM_FIELDS.STATE]
                }
              >
                <Select
                  showSearch
                  name={FORM_FIELDS.STATE}
                  placeholder="Select State"
                  optionFilterProp="children"
                  value={values[FORM_FIELDS.STATE]}
                  onChange={(value) => setFieldValue(FORM_FIELDS.STATE, value)}
                  // onSearch={() => {}}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={states}
                  disabled={formDisabled}
                />
              </ElementWithLabel>
              <ElementWithLabel
                label="Country"
                name={FORM_FIELDS.CATEGORY}
                error={
                  errors[FORM_FIELDS.CATEGORY] &&
                  touched[FORM_FIELDS.CATEGORY] &&
                  errors[FORM_FIELDS.CATEGORY]
                }
              >
                <Select
                  // showSearch
                  name={FORM_FIELDS.CATEGORY}
                  placeholder="Country"
                  optionFilterProp="children"
                  value={"IN"}
                  onChange={(value) =>
                    setFieldValue(FORM_FIELDS.CATEGORY, value)
                  }
                  // onSearch={() => {}}
                  options={[{ label: "India", value: "IN" }]}
                  disabled
                />
              </ElementWithLabel>
              {/* <Input
                id={FORM_FIELDS.COUNTRY}
                label="Country"
                name={FORM_FIELDS.COUNTRY}
                value={values[FORM_FIELDS.COUNTRY]}
                error={
                  errors[FORM_FIELDS.COUNTRY] &&
                  touched[FORM_FIELDS.COUNTRY] &&
                  errors[FORM_FIELDS.COUNTRY]
                }
                onChange={handleChange}
                disabled={inProgress}
              /> */}
            </div>

            <div className="mt-10 border-t border-gray-200 pt-10 flex flex-col gap-3">
              <h2 className="text-lg font-medium text-gray-900">
                Contact information
              </h2>

              <div className="flex gap-3 w-full">
                <Input
                  id={FORM_FIELDS.MOBILE}
                  label="Contact No."
                  name={FORM_FIELDS.MOBILE}
                  value={values[FORM_FIELDS.MOBILE]}
                  error={
                    errors[FORM_FIELDS.MOBILE] &&
                    touched[FORM_FIELDS.MOBILE] &&
                    errors[FORM_FIELDS.MOBILE]
                  }
                  onChange={handleChange}
                  disabled={formDisabled}
                />

                <Input
                  id={FORM_FIELDS.ALTERNATE_MOBILE}
                  type={"number"}
                  label="Alternate Contact No."
                  name={FORM_FIELDS.ALTERNATE_MOBILE}
                  value={values[FORM_FIELDS.ALTERNATE_MOBILE]}
                  error={
                    errors[FORM_FIELDS.ALTERNATE_MOBILE] &&
                    touched[FORM_FIELDS.ALTERNATE_MOBILE] &&
                    errors[FORM_FIELDS.ALTERNATE_MOBILE]
                  }
                  onChange={handleChange}
                  disabled={formDisabled}
                />
              </div>
              <Input
                id={"email"}
                type="email"
                label="Enter Email"
                name={FORM_FIELDS.EMAIL}
                value={values[FORM_FIELDS.EMAIL]}
                error={
                  errors[FORM_FIELDS.EMAIL] &&
                  touched[FORM_FIELDS.EMAIL] &&
                  errors[FORM_FIELDS.EMAIL]
                }
                onChange={handleChange}
                disabled={formDisabled}
              />
            </div>
          </div>

          {/* Order summary */}
          {/* <div className="mt-10 lg:mt-0">
          
            <ImageSelector label="Cover Photo" />

            <div className="mt-10 border-t border-gray-200 pt-10 flex flex-col gap-3">
              <h2 className="text-lg font-medium text-gray-900">
                Gallery Images
              </h2>

              <div className="grid grid-cols-2 gap-3 w-full">
                <ImageSelector />
                <ImageSelector />
                <ImageSelector />
                <ImageSelector />
              </div>
            </div>
          </div> */}
        </form>
      </div>
    </div>
  );
}
